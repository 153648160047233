/* eslint-disable */
import Fetch from 'fetch';

let config = {};
let reportSuite;
export default class Adobe {
    static init(vendorConfig) {
        const url = `/site-olb/v1/analytics/config`;
        Fetch.get(url)
            // .then((resp) => resp.data)
            .then(function (data) {
                if (data && data.success) {
                    config = data;
                    const { appConfigStore } = vendorConfig;
                    reportSuite = appConfigStore.appConfig.reportSuite;
                }
            })
            .catch(function (error) {
                config = {};
                console.log(error);
            });
    }
    static handleEvent(eventObject) {
        try {
            if (!config.model) {
                return;
            }
            var wa = new Object();

            var hostname = window.location.hostname;

            var s_account;

            var isInternalDomain;

            var defaultPage;

            /************************** START CONFIG SECTION **************************/

            // wa.reportSuiteQA=reportSuiteQA;

            wa.reportSuiteQA = eventObject.suite
                ? eventObject.suite
                : reportSuite
                ? reportSuite
                : 'intuitifidsdpqa';

            s_account = wa.reportSuiteQA;

            var s = s_gi(s_account);

            wa.internalDomains = ''; //comma seperate list

            wa.defaultSiteName = eventObject.site;

            wa.defaultSiteGroup = 'app';

            wa.bu = 'ifid';

            /* ************************** END CONFIG SECTION ***************************/

            /*Standard Configs*/

            s.charSet = 'UTF-8';

            s.currencyCode = 'USD';

            s.trackDownloadLinks = true;

            s.trackExternalLinks = false;

            s.trackInlineStats = true;

            s.linkDownloadFileTypes =
                'exe,zip,wav,mp3,mov,mpg,avi,wmv,doc,pdf,xls,docx,pptx,xlsx';

            s.linkLeaveQueryString = false;

            s.linkTrackVars = '';

            s.linkTrackEvents = '';

            s.server = hostname;

            //Set Default Variables

            var curl = location.href.toLowerCase();

            var rurl = location.hostname.toLowerCase();

            var rdomain = rurl.replace(/(\/\/[^\/]+\/).*!/, '$1');

            var cleanDomain = rdomain.replace(/www.|http:|https:|\//g, '');

            var atgShopperId = getCookie('SHOPPER_USER_ID');

            var urlRegExp = /(\w+):\/\/([\w.:-]+)\/?([\w.\/-]*\/)?([^\/?]*)\??(\S*)/;

            var urlArray = curl.match(urlRegExp);

            var defaultPath = urlArray[3] != undefined ? urlArray[3] : '';

            if (urlArray[4]) {
                defaultPage = urlArray[4];
            } else {
                if (!urlArray[4]) {
                    defaultPage = 'index';
                } else {
                    defaultPage = '';
                }
            }

            if (defaultPage) {
                defaultPage = defaultPage.replace(
                    /.php|.jsp|.cfm|.com|.shtml|.html|.htm/g,
                    '',
                );
            }

            if (defaultPath) {
                defaultPath = defaultPath.replace(/\/$/g, '');
            }

            //Check for variables set on page else use default values

            if (wa.siteName == null || typeof wa.siteName == 'undefined') {
                wa.siteName = eventObject.site;
            }

            if (wa.pageDir == null || typeof wa.pageDir == 'undefined') {
                wa.pageDir = defaultPath;
            } else {
                wa.pageDir = wa.pageDir.toLowerCase();
            }

            if (wa.pageSubDir == null || typeof wa.pageSubDir == 'undefined') {
                wa.pageSubDir = '';
            } else {
                wa.pageSubDir = wa.pageSubDir.toLowerCase();
            }

            if (wa.pageDetail == null || typeof wa.pageDetail == 'undefined') {
                wa.pageDetail = defaultPage;
            } else {
                wa.pageID = wa.pageDetail.toLowerCase();
            }

            if (wa.pageFunc == null || typeof wa.pageFunc == 'undefined') {
                wa.pageFunc = '';
            } else {
                wa.pageFunc = wa.pageFunc.toLowerCase();
            }

            if (wa.siteGroup == null || typeof wa.siteGroup == 'undefined') {
                wa.siteGroup = wa.defaultSiteGroup;
            } else {
                wa.siteGroup = wa.siteGroup.toLowerCase();
            }

            //Omniture Variable Configuration

            s.eVar1 = s.prop1 = wa.bu;

            if (s.prop1 != '') {
                s.eVar2 = s.prop2 = s.prop1 + ':' + wa.siteName;
            } else {
                s.eVar2 = s.prop2 = wa.siteName;
            }

            if (s.prop2) {
                s.eVar3 = s.prop3 = s.prop2 + ':' + wa.siteGroup;
            } else {
                s.eVar3 = s.prop3 = wa.siteGroup;
            }

            if (wa.pageDir) s.pageName = wa.pageDir;

            if (wa.pageSubDir)
                s.pageName = s.pageName
                    ? s.pageName + '/' + wa.pageSubDir
                    : wa.pageSubDir;

            if (s.pageName) {
                s.prop4 = s.eVar4 = s.pageName + ' |' + s.prop3;
            } else {
                s.prop4 = s.eVar4 = wa.pageDetail + ' |' + s.prop3;
            }

            if (wa.pageDetail)
                s.pageName = s.pageName
                    ? s.pageName + '/' + wa.pageDetail + ' |' + s.prop3
                    : wa.pageDetail + ' |' + s.prop3;

            if (config.model.guid) {
                s.prop10 = s.eVar10 = config.model.guid;
            }
            if (config.model.diid) {
                s.prop11 = s.eVar11 =
                    config.model.diid + ' - ' + config.model.fiName;
            }
            if (config.model.subUser != undefined) {
                s.prop5 = s.eVar5 = config.model.subUser;
            }
            if (config.model.customerType != undefined) {
                s.prop9 = s.eVar9 = config.model.customerType;
            }

            s.prop6 = s.eVar6 = eventObject.action;

            s.eVar27 = curl;

            s.channel = wa.siteName;

            s.prop15 = s.eVar15 = calculateDate();

            if (atgShopperId) {
                s.eVar41 = s.prop41 = '|' + atgShopperId + '|';
            }

            s.usePlugins = true;

            s.pageURL = curl;

            s.eVar17 = s.eVar18 = document.referrer.toLowerCase();

            let actionProps = eventObject.props;

            s.linkTrackVars =
                'prop1,prop2,prop3,prop4,prop5,prop6,prop7,prop8,prop9,prop10,prop11,prop15,prop26,prop28,prop42,event,';

            s.linkTrackVars =
                s.linkTrackVars +
                'eVar1,eVar2,eVar3,eVar4,eVar5,eVar6,eVar7,eVar8,eVar9,eVar10,eVar11,eVar15,eVar17,eVar18,eVar26,eVar27,eVar28,eVar42';

            if (actionProps) {
                const attributes = Object.keys(actionProps);
                const values = Object.values(actionProps);

                for (let idx = 0, len = attributes.length; idx < len; idx++) {
                    s[attributes[idx]] = values[idx];
                    s.linkTrackVars += ',' + attributes[idx];
                    if (s[attributes[idx]] == 'event') {
                        s.linkTrackEvents = values[idx];
                    }
                }
            }

            function calculateDate() {
                var REPORTING_OFFSET = '-8';
                // create Date object for current location
                var clientDate = new Date();
                // convert to milliseconds and add local timezone offset to get UTC time in milliseconds
                var utcDate =
                    clientDate.getTime() +
                    clientDate.getTimezoneOffset() * 60000;
                // create new Date object accounting for offset
                var serverDate = new Date(utcDate + 3600000 * REPORTING_OFFSET);
                // return time as a string
                return (
                    serverDate.getFullYear() +
                    '|' +
                    (serverDate.getMonth() + 1) +
                    '|' +
                    serverDate.getDate()
                );
            }

            function getCookie(c_name) {
                if (document.cookie.length > 0) {
                    let c_start = document.cookie.indexOf(c_name + '=');

                    if (c_start != -1) {
                        c_start = c_start + c_name.length + 1;

                        c_end = document.cookie.indexOf(';', c_start);

                        if (c_end == -1) c_end = document.cookie.length;

                        return unescape(
                            document.cookie.substring(c_start, c_end),
                        );
                    }
                }

                return '';
            }

            /*Utility Function: split v1.5 - split a string (JS 1.0 compatible)*/

            s.split = new Function(
                'l',
                'd',
                '' +
                    'var i,x=0,a=new Array;while(l){i=l.indexOf(d);i=i>-1?i:l.length;a[x' +
                    '++]=l.substring(0,i);l=l.substring(i+d.length);}return a',
            );

            /*

             * s.join: 1.0 - s.join(v,p)

             *

             *  v - Array (may also be array of array)

             *  p - formatting parameters (front, back, delim, wrap)

             *
             */

            s.join = new Function(
                'v',
                'p',
                '' +
                    "var s = this;var f,b,d,w;if(p){f=p.front?p.front:'';b=p.back?p.back" +
                    ":'';d=p.delim?p.delim:'';w=p.wrap?p.wrap:'';}var str='';for(var x=0" +
                    ";x<v.length;x++){if(typeof(v[x])=='object' )str+=s.join( v[x],p);el" +
                    'se str+=w+v[x]+w;if(x<v.length-1)str+=d;}return f+str+b;',
            );

            /*

             * Plugin: s.crossVisitParticipation : 1.2 - stacks values from

             * specified variable in cookie and returns value

             */

            s.crossVisitParticipation = new Function(
                'v',
                'cn',
                'ex',
                'ct',
                'dl',
                'ev',
                '' +
                    "var s=this;var ay=s.split(ev,',');for(var u=0;u<ay.length;u++){if(s" +
                    ".events&&s.events.indexOf(ay[u])!=-1){s.c_w(cn,'');return '';}}if(!" +
                    "v||v=='')return '';var arry=new Array();var a=new Array();var c=s.c" +
                    "_r(cn);var g=0;var h=new Array();if(c&&c!='') arry=eval(c);var e=ne" +
                    'w Date();e.setFullYear(e.getFullYear()+5);if(arry.length>0&&arry[ar' +
                    'ry.length-1][0]==v)arry[arry.length-1]=[v, new Date().getTime()];el' +
                    'se arry[arry.length]=[v, new Date().getTime()];var data=s.join(arry' +
                    ",{delim:',',front:'[',back:']',wrap:'\\''});var start=arry.length-c" +
                    't < 0?0:arry.length-ct;s.c_w(cn,data,e);for(var x=start;x<arry.leng' +
                    'th;x++){var diff=Math.round(new Date()-new Date(parseInt(arry[x][1]' +
                    ')))/86400000;if(diff<ex){h[g]=arry[x][0];a[g++]=arry[x];}}var r=s.j' +
                    'oin(h,{delim:dl});return r;',
            );

            /*

             * Plugin: getAndPersistValue 0.3 - get a value on every page


             */

            s.getAndPersistValue = new Function(
                'v',
                'c',
                'e',
                '' +
                    'var s=this,a=new Date;e=e?e:0;a.setTime(a.getTime()+e*86400000);if(' +
                    'v)s.c_w(c,v,e?a:0);return s.c_r(c);',
            );

            function s_doPlugins(s) {
                // The within-domain campaign tracking variable that is pulled from a query string parameter.
                s.eVar21 = s.prop21 = s.Util.getQueryParam('scid', '', curl);

                // The cross-domain campaign tracking variable that value is pulled from a query string parameter.
                s.eVar22 = s.prop22 = s.Util.getQueryParam('xcid', '', curl);

                // The priority code tracking variable that is pulled from a query string parameter.
                s.eVar23 = s.prop23 = s.Util.getQueryParam(
                    'priorityCode',
                    '',
                    curl,
                );

                //Campaign tracking
                var cid = s.Util.getQueryParam('cid', '', curl);
                if (cid) {
                    s.campaign = cid;
                    s.eVar25 = s.prop25 = cid;
                    s.prop42 = s.eVar42 = s.crossVisitParticipation(
                        cid,
                        's_cpm',
                        '180',
                        '7',
                        '>',
                        'event53',
                    ); //* Campaign stacking
                }

                /*
                 * URS logic follows...,
                 */
                let ursvar = '';
                let ppckw = '';
                let natkw = '';
                if (s.campaign) {
                    ursvar = s.campaign;
                }
                if (s.campaign == '' && cleanDomain == '') {
                    ursvar = '';
                } else {
                    let orgDomains = new Array(
                        'bing.',
                        'q',
                        'a9.',
                        '*,q',
                        'abacho.',
                        'q',
                        'ah-ha.',
                        'q',
                        'alexa.',
                        'q',
                        'allesklar.',
                        'wo,words',
                        'alltheweb.',
                        'q,query',
                        'altavista.',
                        'q',
                        'aol.',
                        'query',
                        'arianna.',
                        'query,b1',
                        'asiaco.',
                        'query,qry',
                        'ask.',
                        'q,ask',
                        'atlas.',
                        'q',
                        'austronaut.',
                        'begriff,suche',
                        'auyantepui.',
                        'clave',
                        'bluewin.',
                        'qry,q',
                        'centrum.',
                        'q',
                        'club-internet.',
                        'q',
                        'dino-online.',
                        'query',
                        'dir.com.',
                        'req',
                        'dmoz.',
                        'search',
                        'dogpile.',
                        'q,qkw',
                        'eniro.',
                        'q',
                        'euroseek.',
                        'string,query',
                        'exalead.',
                        'q',
                        'excite.',
                        'search,s,qkw',
                        'findlink.',
                        'key',
                        'findwhat.',
                        'mt',
                        'fireball.',
                        'q',
                        'freeserve.',
                        'q',
                        'gigablast.',
                        'q',
                        'go2net.',
                        'general',
                        'goeureka.',
                        'key',
                        'google.',
                        'q,as_q,as_epq,as_oq',
                        'googlesyndication.',
                        'url',
                        'greekspider.',
                        'keywords',
                        'hotbot.',
                        'query,mt',
                        'ilor.',
                        'q',
                        'iltrovatore.',
                        'q',
                        'index.nana.co.il.',
                        'q',
                        'infoseek.',
                        'qt,q',
                        'infospace.',
                        'qkw',
                        'intuitsearch.',
                        'q',
                        'iwon.',
                        'searchfor',
                        'ixquick.',
                        'query',
                        'jubii.',
                        'query,soegeord',
                        'jyxo.',
                        's',
                        'kanoodle.',
                        'query',
                        'kataweb.',
                        'q',
                        'kvasir.',
                        'q',
                        'live.',
                        'q',
                        'looksmart.',
                        'qt,key,querystring',
                        'lycos.',
                        'query,mt,q,qry',
                        'mamma.',
                        'query',
                        'metacrawler.',
                        'q,general,qry',
                        'msn.',
                        'q,mt',
                        'mywebsearch.',
                        'searchfor',
                        'mysearch.',
                        'searchfor',
                        'netex.',
                        'srchkey,keyword',
                        'netscape.',
                        'search,searchstring,query',
                        'netster.',
                        'keywords',
                        'nettavisen.',
                        'query,q',
                        'ninemsn.',
                        'q',
                        'nlsearch.',
                        'qr',
                        'nomade.',
                        'mt,s',
                        'northernlight.',
                        'qr',
                        'oozap.',
                        'query',
                        'overture.',
                        'keywords',
                        'ozu.',
                        'q',
                        'passagen.',
                        'q',
                        'quick.',
                        'ftxt_query',
                        'savvy.',
                        's',
                        'scrubtheweb.',
                        'keyword,q',
                        'www.search.com.',
                        'q',
                        'searchalot.',
                        'q',
                        'searchhippo.',
                        'q',
                        'sensis.',
                        'find',
                        'seznam.',
                        'w',
                        'soneraplaza.',
                        'qt',
                        'splatsearch.',
                        'searchstring',
                        'sprinks.',
                        'terms',
                        'spray.',
                        'query',
                        'srch.',
                        'q',
                        'supereva.',
                        'q',
                        'teoma.',
                        'q',
                        'thunderstone.',
                        'q',
                        'tiscali.ch.',
                        'key',
                        'tjohoo.',
                        'soktext,mt,query',
                        'track.',
                        'qr',
                        'truesearch.',
                        'query',
                        'tygo.',
                        's',
                        'vinden.',
                        'query',
                        'virgilio.',
                        'qs',
                        'vivisimo.',
                        'query',
                        'voila.',
                        'kw',
                        'walla.',
                        'q',
                        'wanadoo.',
                        'fkw',
                        'web.',
                        'su',
                        'webcrawler.',
                        'qkw,search,searchtext',
                        'webwatch.',
                        'findindb',
                        'wepa.',
                        'query',
                        'wisenut.',
                        'q',
                        'xpsn.',
                        'kwd',
                        'ya.',
                        'q',
                        'yahoo.',
                        'p,va,vp,vo',
                        'ynet.',
                        'q',
                        'zerx.',
                        'search',
                    );
                    let domainFound = '';
                    for (var i = 0; i < orgDomains.length; ++i) {
                        if (domainFound != '') {
                            orgParams = orgDomains[i].split(',');
                            for (j = 0; j < orgParams.length; j++) {
                                var parsekw = s.Util.getQueryParam(
                                    orgParams[j],
                                    '',
                                    rurl,
                                );
                                if (parsekw) {
                                    if (ursvar) {
                                        ppckw = parsekw;
                                        break;
                                    } else {
                                        ursvar = cleanDomain + ' [seo]';
                                        natkw = parsekw;
                                        break;
                                    }
                                }
                            }
                        }
                        if (
                            orgDomains[i].indexOf('.') >= 0 &&
                            rurl.indexOf(orgDomains[i]) >= 0
                        ) {
                            domainFound = orgDomains[i];
                        }
                        if (parsekw) {
                            break;
                        }
                    }
                    if (!parsekw && !s.campaign && cleanDomain) {
                        ursvar = cleanDomain + ' [ref]';
                    }
                }

                s.prop28 = s.eVar28 = ursvar;
                s.prop42 = s.eVar42 = s.crossVisitParticipation(
                    ursvar,
                    's_cpm',
                    '180',
                    '7',
                    '>',
                    'event53',
                ); //* Campaign stacking
                s.prop29 = s.eVar29 = ppckw;
                s.prop30 = s.eVar30 = natkw;

                let stickyCid = s.getAndPersistValue(ursvar, 'ursvar');
                if (stickyCid) {
                    s.eVar26 = s.prop26 = stickyCid + ' -- ' + s.pageName;
                } else {
                    s.eVar26 = s.prop26 = s.pageName;
                }

                //*Get paid search keyword if not found in rurl try
                if (!ppckw)
                    s.prop29 = s.eVar29 = ppckw = s.Util.getQueryParam(
                        'kw',
                        '',
                        curl,
                    );
            }

            s.doPlugins = s_doPlugins;

            /* WARNING: Changing any of the below variables will cause drastic

             changes to how your visitor data is collected.  Changes should only be

             made when instructed to do so by your account manager.*/

            s.visitorNamespace = 'intuitinc';

            s.dc = 122;

            s.trackingServer = 'ci.digitalinsight.com';

            s.trackingServerSecure = 'sci.digitalinsight.com';

            s.tl(true, 'o', eventObject.action, null, 'navigate');
        } catch (e) {
            console.log('Error in analytics', e);
        }
    }
}