// eslint-disable-next-line filenames/match-exported
import React, { useState, useCallback, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const initialValue = {
    modernBrandingEnabled: false,
    modernBrandingEnabledForAccountHistory: false,
    toggleMuiBranding: () => undefined,
};

export const ModernBrandingContext = createContext(initialValue);

export function ModernBrandingProvider({
    brandingEnabled,
    brandingEnabledForAccountHistory,
    children,
}) {
    const [modernBrandingEnabled, SetMuiBrandingEnabled] = useState(
        brandingEnabled,
    );

    const [
        modernBrandingEnabledForAccountHistory,
        setModernBrandingEnabledForAccountHistory,
    ] = useState(brandingEnabledForAccountHistory);

    const setLocalStorage = useCallback((enabled) => {
        localStorage.setItem('modernBrandingEnabled', enabled);
    }, []);

    const toggleMuiBranding = () => {
        SetMuiBrandingEnabled(!modernBrandingEnabled);
        setModernBrandingEnabledForAccountHistory(
            !modernBrandingEnabledForAccountHistory,
        );
        setLocalStorage(!modernBrandingEnabled);
    };

    return (
        <ModernBrandingContext.Provider
            value={{
                modernBrandingEnabled,
                modernBrandingEnabledForAccountHistory,
                toggleMuiBranding,
            }}
        >
            {children}
        </ModernBrandingContext.Provider>
    );
}

ModernBrandingProvider.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    brandingEnabled: PropTypes.bool,
    // eslint-disable-next-line react/boolean-prop-naming
    brandingEnabledForAccountHistory: PropTypes.bool,
};

export const useModernBranding = () => {
    return useContext(ModernBrandingContext);
};
